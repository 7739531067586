<template>
    <div style="background: #E9ecef; min-height: 100vh">
        <nav class="navbar navbar-expand-md fixed-top navbar-shrink py-3 bg-light" id="mainNav">
            <div class="container"><a class="navbar-brand d-flex align-items-center" href="/"><span
                        style="color: #FF5E21;">UMOJA</span></a><button data-bs-toggle="collapse" class="navbar-toggler"
                    data-bs-target="#navcol-1"><span class="visually-hidden">Toggle navigation</span><span
                        class="navbar-toggler-icon"></span></button>
                <div class="collapse navbar-collapse" id="navcol-1">
                    <ul class="navbar-nav mx-auto">
                        <li class="nav-item"><a class="nav-link black--text" href="/Home/index.html">Home</a></li>
                        <li class="nav-item"><a class="nav-link black--text" href="/Home/about.html">About us</a></li>
                        <li class="nav-item"><a class="nav-link black--text" href="#/apply">Scholarships</a>
                        </li>
                        <li class="nav-item"><a class="nav-link black--text"
                                href="https://4a83797c-da23-4513-981f-0c9eefda5181.paylinks.godaddy.com/3f346470-82e4-49c7-aff4-c61">Donation</a>
                        </li>
                        <li class="nav-item"><a class="nav-link black--text" href="/Home/jobs.html">Jobs</a></li>
                        <li class="nav-item"><a class="nav-link black--text" href="/Home/contacts.html">Contacts</a>
                        </li>
                    </ul>
                    <!-- <a class="btn shadow white--text px-5" style="background-color: #24285b;" role="button"
                        href="#/login">Sign in</a> -->
                </div>
            </div>
        </nav>
        <div class="d-flex justify-content-center align-content-center" style="width: 100%;height: 100%;">
            <div class="container">
                <div class="row d-flex justify-content-center" style="padding: 100px 0px;margin: 1x0px 0px;">
                    <div class="col-md-12 col-xxl-8" data-aos="slide-down" data-aos-duration="500"
                        style="text-align: center;padding: 35px 0px;">
                        <h1><span style="color: rgb(32, 33, 36);">Umoja Scholarship Application</span></h1>
                        <h1 style="font-size: 20px;color: #FF5E21;">Reminder: Test Closes on April 28th 2024, at 11:59
                            pm</h1>
                    </div>
                    <!-- <h4>{{student}}</h4> -->

                    <div class="col-md-9 col-lg-6" style="background: white;padding: 15px 25px;">
                        <div style="padding: 20px 0px;text-align: center;">
                            <h1 style="font-size: 30px;"><span style="color: rgb(32, 33, 36);">Apply Now</span></h1>
                            <h1 style="font-size: 16px;"><span style="color: rgb(32, 33, 36);">Fields with the
                                    asterix&nbsp;sign are required</span></h1>
                            <h4 class="red--text" v-if="errorMessage">{{ errorMessage }}</h4>
                        </div>
                        <div style="padding: 0px 0px 10px;">
                            <form style="padding: 10px 25px 0px;" action="" method="POST">
                                <!-- data-bss-recipient="f492b2d849323bc975933d115f55e15a" -->
                                <!-- part 1 -->
                                <div v-if="part==1">
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Email Address:<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>&nbsp;</h1><input
                                            class="form-control" type="text" placeholder="Enter your email address"
                                            style="margin: 10px 0px;" v-model="student.email">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Full Name:<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span></h1><input
                                            class="form-control" type="text" placeholder="Enter your name"
                                            style="margin: 10px 0px;" v-model="student.fullname">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="500" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Address:<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span></h1><input
                                            class="form-control" type="text" placeholder="Enter your home address"
                                            style="margin: 10px 0px;" v-model="student.address">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="700" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Phone Number:
                                            <span style="font-size: 25px;color: red;">*&nbsp;</span></h1><input
                                            class="form-control" type="text" placeholder="Enter your phone number"
                                            style="margin: 10px 0px;" v-model="student.phone">
                                    </div>
                                </div>

                                <!-- part 2 -->
                                <div style="padding: 20px 0px 0px;" v-show="part==2">
                                    <h1
                                        style="font-size: 25px;font-weight: bold;background: rgba(167,167,167,0.2);color: initial;padding: 10px;">
                                        Parents/Guardian</h1>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Full name:<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>&nbsp;</h1><input
                                            class="form-control" type="text" placeholder="Parent/Guardian Full name"
                                            style="margin: 10px 0px;" v-model="student.parent_fullname">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Email:<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span></h1><input
                                            class="form-control" type="text" placeholder="Parent/Guardian Email"
                                            style="margin: 10px 0px;" v-model="student.parent_email">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="500" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Phone Number:
                                            <span style="font-size: 25px;color: red;">*&nbsp;</span></h1><input
                                            class="form-control" type="text" placeholder="Paret/Guardian Phone Number"
                                            style="margin: 10px 0px;" v-model="student.parent_phone">
                                    </div>
                                </div>

                                <!-- part 3 -->
                                <div style="padding: 20px 0px 0px;" v-show="part==3">
                                    <h1
                                        style="font-size: 25px;font-weight: bold;background: rgba(167,167,167,0.2);color: initial;padding: 10px;">
                                        About You</h1>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">What do you want to be when you
                                            grow up?<span style="font-size: 25px;color: red;">*</span>&nbsp;</h1><input
                                            class="form-control" type="text" placeholder="Enter your answer here"
                                            style="margin: 10px 0px;" v-model="student.when_grow_up">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Are you a current high school
                                            student?<span style="font-size: 25px;color: red;">*</span></h1>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-1" v-model="student.in_high_school" name="inHighSchool"
                                                value="Yes"><label class="form-check-label" for="formCheck-1"
                                                style="font-size: 20px;">Yes</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-2" v-model="student.in_high_school" name="inHighSchool"
                                                value="No"><label class="form-check-label" for="formCheck-2"
                                                style="font-size: 20px;">No</label></div>
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">What year are you currently
                                            in?<span style="font-size: 25px;color: red;">*</span></h1>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-3" v-model="student.year_currently_in" name="currentlyIn"
                                                value="Freshmen"><label class="form-check-label" for="formCheck-3"
                                                style="font-size: 20px;">Freshmen</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-4" v-model="student.year_currently_in" name="currentlyIn"
                                                value="Sophmore"><label class="form-check-label" for="formCheck-4"
                                                style="font-size: 20px;">Sophomore</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-5" v-model="student.year_currently_in" name="currentlyIn"
                                                value="Junior"><label class="form-check-label" for="formCheck-5"
                                                style="font-size: 20px;">Junior</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-6" v-model="student.year_currently_in" name="currentlyIn"
                                                value="Senior"><label class="form-check-label" for="formCheck-6"
                                                style="font-size: 20px;">Senior</label></div>
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">Graduation Year?<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span></h1>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-7" v-model="student.graduation_year" name="graduationYear"
                                                value="2024"><label class="form-check-label" for="formCheck-7"
                                                style="font-size: 20px;">2024</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-8" v-model="student.graduation_year" name="graduationYear"
                                                value="2025"><label class="form-check-label" for="formCheck-8"
                                                style="font-size: 20px;">2025</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-9" v-model="student.graduation_year" name="graduationYear"
                                                value="2026"><label class="form-check-label" for="formCheck-9"
                                                style="font-size: 20px;">2026</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-10" v-model="student.graduation_year"
                                                name="graduationYear" value="2027"><label class="form-check-label"
                                                for="formCheck-10" style="font-size: 20px;">2027</label></div>
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">What is your favorite class?<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>&nbsp;</h1><input
                                            class="form-control" type="text" placeholder="Enter your answer here"
                                            style="margin: 10px 0px;" v-model="student.favorite_class">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true">
                                        <h1 style="font-size: 20px;font-weight: bold;">What Level of ELL are you?<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>&nbsp;</h1><input
                                            class="form-control" type="text" placeholder="Enter your answer here"
                                            style="margin: 10px 0px;" v-model="student.level">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true" style="padding: 15px 0px 0px;">
                                        <h1 style="font-size: 20px;font-weight: bold;">What do you want to study after
                                            High School? Explain why?<span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>&nbsp;</h1><input
                                            class="form-control" type="text" placeholder="Enter your answer here"
                                            style="margin: 10px 0px;" v-model="student.study_after_high_school">
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true">
                                        <h1 style="font-size: 20px;padding: 15px 0px 0px;font-weight: bold;">In a few
                                            words, tell us why, and how this scholarship will help you in your future
                                            academic endeavors.<span style="font-size: 25px;color: red;">*</span>&nbsp;
                                        </h1><textarea rows="3" class="form-control" type="text"
                                            placeholder="Enter your answer here" style="margin: 10px 0px;"
                                            v-model="student.why_scholarship"></textarea>
                                    </div>
                                </div>

                                <!-- part 4 -->
                                <div style="padding: 20px 0px 0px;" v-show="part==4">
                                    <h1
                                        style="font-size: 25px;font-weight: bold;background: rgba(167,167,167,0.2);color: initial;padding: 10px;">
                                        Scholarship Ceremony</h1>
                                    <div class="mt-5" data-aos="zoom-out-right" data-aos-duration="1000"
                                        data-aos-delay="100" data-aos-once="true" style="padding: 15px 0px;">
                                        <h1 style="font-size: 18px;">Important to Remember about Scholarship<br>Ceremony
                                            Date: May 4th, 2024 <br>Time: 12pm to 3pm <br>Location: Coralville
                                            Library - Room A</h1>
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;padding: 10px 0px;font-weight: bold;"><span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>By submitting this
                                            application, please note that attendance at the scholarship ceremony will be
                                            required</h1>
                                        <div class="form-check"><input class="form-check-input" type="checkbox"
                                                id="formCheck-21" v-model="student.by_submitting" name="bySubmit"><label
                                                class="form-check-label" for="formCheck-21"
                                                style="font-size: 20px;">Yes, I understand</label></div>
                                    </div>

                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;padding: 10px 0px;font-weight: bold;"><span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>Where do you live?
                                        </h1>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-17" v-model="student.where_do_you_live" name="city"
                                                value="Iowa City" @click="pickCity('Iowa City')"><label
                                                class="form-check-label" for="formCheck-17"
                                                style="font-size: 20px;">Iowa City</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-18" v-model="student.where_do_you_live" name="city"
                                                value="Coralville" @click="pickCity('Coralville')"><label
                                                class="form-check-label" for="formCheck-18"
                                                style="font-size: 20px;">Coralville</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-19" v-model="student.where_do_you_live" name="city"
                                                value="North Liberty" @click="pickCity('North Liberty')"><label
                                                class="form-check-label" for="formCheck-19"
                                                style="font-size: 20px;">North Liberty</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-20" v-model="student.where_do_you_live" name="city"
                                                value="Cedar Rapids" @click="pickCity('Cedar Rapids')"><label
                                                class="form-check-label" for="formCheck-20"
                                                style="font-size: 20px;">Cedar Rapids</label></div>
                                        <div class="form-check"><input class="form-check-input" type="radio"
                                                id="formCheck-12" v-model="student.where_do_you_live" name="city"
                                                value="" @click="pickCity('Other')"><label class="form-check-label"
                                                for="formCheck-12" style="font-size: 20px;">Other:</label></div><input
                                            class="form-control" type="text" placeholder="Enter your answer here"
                                            style="margin: 10px 0px;" v-model="student.where_do_you_live"
                                            v-if="txtOther">
                                    </div>
                                </div>

                                <!-- part 5 -->
                                <div style="padding: 20px 0px 0px;" v-show="part==5">
                                    <h1
                                        style="font-size: 25px;font-weight: bold;background: rgba(167,167,167,0.2);color: initial;padding: 10px;">
                                        Form Submission</h1>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;padding: 10px 0px;font-weight: bold;"><span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>Please confirm that
                                            all the
                                            information you have provided is true and that it is your own.</h1>
                                        <div class="form-check"><input class="form-check-input" type="checkbox"
                                                id="formCheck-15" v-model="student.I_acknoloedge" name="confirm"><label
                                                class="form-check-label" for="formCheck-15" style="font-size: 20px;">I
                                                acknowledge that all information provided is
                                                from me and is true.</label></div>
                                    </div>
                                    <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000"
                                        data-aos-delay="300" data-aos-once="true">
                                        <h1 style="font-size: 20px;padding: 10px 0px;font-weight: bold;"><span
                                                style="font-size: 25px;color: red;">*&nbsp;</span>Do you understand that
                                            to be
                                            eligible for the Umoja Scholarship, you must complete the ELL test that
                                            follows this form?</h1>
                                        <div class="form-check"><input class="form-check-input" type="checkbox"
                                                id="formCheck-16" v-model="student.do_you_understand"
                                                name="understand"><label class="form-check-label" for="formCheck-16"
                                                style="font-size: 20px;">Yes, I do</label></div>
                                    </div>
                                </div>

                                <!-- part 5 -->
                                <div style="padding: 20px 0px 0px;text-align: center;" v-show="part==5">
                                    <img class="img-fluid" src="assets/img/goodluck.jpeg">
                                </div>

                                <!-- part 6 -->
                                <div class="col-md-12 col-xxl-8" style="text-align: center;padding: 35px 0px 10px;">
                                    <div class="btn-group btn-group-lg" role="group">
                                        <button class="btn" type="button" v-if="ShowPrevious" @click="Previous()"
                                            style="color: #FF5E21;background: #E9ecef;font-family: Raleway, sans-serif;">Previous
                                        </button>

                                        <button class="btn" type="button" @click="Next()" v-if="showNext"
                                            style="background: #FF5E21;color: white;font-family: Raleway, sans-serif;">{{btnNext}}
                                        </button>

                                        <button class="btn" v-if="showSubmit" type="button"
                                            style="background: #FF5E21;color: white;font-family: Raleway, sans-serif;">Submit
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase";
    import db from "../db.js";

    export default {
        data() {
            return {
                student: {
                    email: '',
                    fullname: '',
                    address: '',
                    phone: '',
                    parent_fullname: '',
                    parent_email: '',
                    parent_phone: '',
                    when_grow_up: '',
                    in_high_school: '',
                    year_currently_in: '',
                    graduation_year: '',
                    favorite_class: '',
                    level: '',
                    study_after_high_school: '',
                    why_scholarship: '',
                    by_submitting: '',
                    ceremony: '',
                    where_do_you_live: '',
                    I_acknoloedge: '',
                    do_you_understand: '',
                },
                part: 1,
                ShowPrevious: false,
                showNext: true,
                showSubmit: false,
                btnNext: "Next",
                txtOther: true,
                errorMessage: '',
            }
        },
        methods: {
            isEmptyOrSpaces(str) {
                str = str.toString();
                return str === null || str.trim() === '';
            },
            isValidEmail(email) {
                // Regular expression for validating an email address
                var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            },
            // isValidPhoneNumber(phoneNumber) {
            //     // Regular expression for validating a phone number in the format xxx-xxx-xxx
            //     var phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
            //     return phoneRegex.test(phoneNumber);
            // },
            Next() {
                var student = this.student;
                var goNext = true;
                this.errorMessage = "";

                switch (this.part) {
                    case 1:

                        if (this.isEmptyOrSpaces(student.email)) goNext = false;
                        if (this.isEmptyOrSpaces(student.fullname)) goNext = false;
                        if (this.isEmptyOrSpaces(student.address)) goNext = false;
                        if (this.isEmptyOrSpaces(student.phone)) goNext = false;

                        if (goNext == false) {
                            this.errorMessage = "Kindly ensure all fields are completed!"
                            break;
                        }

                        if (!this.isValidEmail(student.email)) {
                            goNext = false;
                            this.errorMessage = "Error: Please, enter a correct email !"
                            break;
                        }

                        // if (!this.isValidPhoneNumber(student.phone)) {
                        //     goNext = false;
                        //     this.errorMessage = "Error: Please enter the phone number in the format (xx-xxx-xxxx) !"
                        //     break;
                        // }

                        this.part = 2;
                        this.ShowPrevious = true;
                        break;
                    case 2:

                        if (this.isEmptyOrSpaces(student.parent_fullname)) goNext = false;
                        if (this.isEmptyOrSpaces(student.parent_email)) goNext = false;
                        if (this.isEmptyOrSpaces(student.parent_phone)) goNext = false;

                        if (goNext == false) {
                            this.errorMessage = "Kindly ensure all fields are completed!"
                            break;
                        }

                        if (!this.isValidEmail(student.parent_email)) {
                            goNext = false;
                            this.errorMessage = "Error: Please, enter a correct email !"
                            break;
                        }

                        // if (!this.isValidPhoneNumber(student.parent_phone)) {
                        //     goNext = false;
                        //     this.errorMessage = "Error: Please enter the phone number in the format (xx-xxx-xxxx) !"
                        //     break;
                        // }

                        this.part = 3;
                        this.ShowPrevious = true;
                        break;
                    case 3:

                        if (this.isEmptyOrSpaces(student.when_grow_up)) goNext = false;
                        if (this.isEmptyOrSpaces(student.in_high_school)) goNext = false;
                        if (this.isEmptyOrSpaces(student.year_currently_in)) goNext = false;
                        if (this.isEmptyOrSpaces(student.graduation_year)) goNext = false;
                        if (this.isEmptyOrSpaces(student.favorite_class)) goNext = false;
                        if (this.isEmptyOrSpaces(student.level)) goNext = false;
                        if (this.isEmptyOrSpaces(student.study_after_high_school)) goNext = false;
                        if (this.isEmptyOrSpaces(student.why_scholarship)) goNext = false;

                        if (goNext == false) {
                            this.errorMessage = "Kindly ensure all fields are completed!"
                            break;
                        }

                        this.part = 4;
                        this.ShowPrevious = true;
                        break;
                    case 4:

                        if (this.isEmptyOrSpaces(student.by_submitting)) goNext = false;
                        if (this.isEmptyOrSpaces(student.where_do_you_live)) goNext = false;

                        if (goNext == false) {
                            this.errorMessage = "Kindly ensure all fields are completed!"
                            break;
                        }

                        this.part = 5;
                        this.ShowPrevious = true;
                        this.btnNext = "Submit";
                        break;
                    case 5:

                        if (this.isEmptyOrSpaces(student.I_acknoloedge)) goNext = false;
                        if (this.isEmptyOrSpaces(student.do_you_understand)) goNext = false;

                        if (goNext == false) {
                            this.errorMessage = "Kindly ensure all fields are completed!"
                            break;
                        }
                        this.checkIfAlreadyApplied();
                        this.showNext = false;
                        break;
                }

                this.scrollUp();
            },
            Previous() {
                switch (this.part) {
                    case 5:
                        this.part = 4;
                        this.ShowPrevious = true;
                        this.showNext = true;
                        this.showSubmit = false;
                        this.btnNext = "Next";
                        break;
                    case 4:
                        this.part = 3;
                        this.ShowPrevious = true;
                        break;
                    case 3:
                        this.part = 2;
                        this.ShowPrevious = true;
                        break;
                    case 2:
                        this.part = 1;
                        this.ShowPrevious = true;
                        this.ShowPrevious = false;
                        break;

                    default:
                        break;
                }

                this.scrollUp();
            },
            pickCity(value) {
                switch (value) {
                    case "Iowa City":
                        this.txtOther = false;
                        break;
                    case "Coralville":
                        this.txtOther = false;
                        break;
                    case "North Liberty":
                        this.txtOther = false;
                        break;
                    case "Cedar Rapids":
                        this.txtOther = false;
                        break;
                    default:
                        this.txtOther = true;
                        break;
                }
            },
            checkIfAlreadyApplied() {
                var that = this;
                var isThere = "";
                var notYet = false;

                try {
                    db.collection("users")
                        .where("email", "==", that.student.email)
                        .onSnapshot(snapshot => {

                            isThere = "";

                            snapshot.forEach(doc => {
                                isThere = doc.data().email
                            });


                            if (notYet == false) {
                                if (isThere == "" || isThere == undefined) {
                                    notYet = true;
                                    this.sendData();
                                } else this.$router.push("/alreadyApplied");
                            }

                        });

                } catch (error) {
                    console.log(error.message);
                }
            },
            sendData() {

                var that = this;
                try {
                    db.collection("users").add({
                        email: that.student.email,
                        fullname: that.student.fullname,
                        address: that.student.address,
                        phone: that.student.phone,
                        parent_fullname: that.student.parent_fullname,
                        parent_email: that.student.parent_email,
                        parent_phone: that.student.parent_phone,
                        when_grow_up: that.student.when_grow_up,
                        in_high_school: that.student.in_high_school,
                        year_currently_in: that.student.year_currently_in,
                        graduation_year: that.student.graduation_year,
                        favorite_class: that.student.favorite_class,
                        level: that.student.level,
                        study_after_high_school: that.student.study_after_high_school,
                        why_scholarship: that.student.why_scholarship,
                        ceremony: that.student.ceremony,
                        where_do_you_live: that.student.where_do_you_live,
                        image: that.$store.state.image,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        year: new Date().getFullYear()
                    });

                    that.$store.state.ye = {
                        email: that.student.email,
                        fullname: that.student.fullname,
                        phone: that.student.phone,
                        year_currently_in: that.student.year_currently_in,
                        graduation_year: that.student.graduation_year,
                        favorite_class: that.student.favorite_class
                    }

                    console.log(that.$store.state.ye);

                    that.showSubmit = false;
                    this.setCookie();

                } catch (error) {
                    alert(error.message);
                    that.errorMessage = error.message;
                    that.ShowPrevious = true;
                    that.showNext = false;
                    that.showSubmit = true;
                }

            },
            setCookie() {
                var that = this;
                const date = new Date();
                date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
                const expires = "expires=" + date.toUTCString();
                document.cookie = 'email' + "=" + that.student.email + ";" + expires + ";path=/";
                document.cookie = 'fullname' + "=" + that.student.fullname + ";" + expires + ";path=/";
                document.cookie = 'phone' + "=" + that.student.phone + ";" + expires + ";path=/";
                document.cookie = 'year_currently_in' + "=" + that.student.year_currently_in + ";" + expires +
                    ";path=/";
                document.cookie = 'graduation_year' + "=" + that.student.graduation_year + ";" + expires + ";path=/";
                document.cookie = 'favorite_class' + "=" + that.student.favorite_class + ";" + expires + ";path=/";

                setTimeout(() => {
                    that.$router.push("/congrats");
                }, 1000);
            },
            scrollUp() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth' // 'smooth' or 'auto'
                });
            }
        }
    }
</script>

<style>

</style>